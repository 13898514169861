import Vue from 'vue'
import Vuex from 'vuex'

import { $api } from '../plugins/api-requests'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        headerMenu: [],
        footerMenu: [],
        user: {},
    },
    mutations: {
        setHeaderMenu(state, posts) {
            if (Array.isArray(posts)) {
                posts.forEach((value, index) => {
                    Vue.set(state.headerMenu, index, value)
                })
                return
            }
            state.headerMenu = posts
        },
        setFooterMenu(state, posts) {
            if (Array.isArray(posts)) {
                posts.forEach((value, index) => {
                    Vue.set(state.footerMenu, index, value)
                })
                return
            }
            state.footerMenu = posts
        },
        setUser(state, user) {
            state.user = user
        },
        logoutUser(state, user) {
            state.user = user
        },
    },
    actions: {
        async setHeaderMenu({ commit }, lang) {
            let posts = []
            let data = await $api.getHeaderMenu(lang)
            data = data.items

            if (Array.isArray(data)) {
                posts = data
            }

            await commit('setHeaderMenu', posts)
        },
        async setFooterMenu({ commit }, lang) {
            let posts = []
            let data = await $api.getFooterMenu(lang)
            data = data.items

            if (Array.isArray(data)) {
                posts = data
            }

            await commit('setFooterMenu', posts)
        },
        async setUser({ commit }, user) {
            let userdata = false
            try {
                userdata = await Vue.auth.fetch()
            } catch (error) {
                console.error(error)
            }

            if (userdata && userdata.data.success == true) {
                user = userdata.data.user
                Vue.auth.user(user)
                Vue.auth.remember(
                    JSON.stringify({
                        name: user.id,
                    })
                )
            } else {
                const checktoken = await $api.checkToken()
                if (checktoken.data.success != true) {
                    await this.dispatch('logoutUser')
                }
            }
            await commit('setUser', user)
        },
        async logoutUser({ commit }, user) {
            user = {}
            Vue.auth.unremember()
            Vue.auth.user(user)
            Vue.auth.logout()

            await commit('logoutUser', user)
        },
    },
    getters: {
        headerMenu: (state) => state.headerMenu,
        footerMenu: (state) => state.footerMenu,
        user: (state) => state.user,
    },
})
