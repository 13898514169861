import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

export const EventBus = new Vue()

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.config.ignoredElements = [/^ion-/]

import { CONFIG } from '../constants'
import i18n from './plugins/i18n'

// Global Vars
let globalsvars = {
    baseurl: CONFIG.BASE_URL,
}
Vue.prototype.$globalvar = globalsvars
// Global Vars

// API Plugin
import ApiRequests from './plugins/api-requests'
Vue.use(ApiRequests, { store, router })
// API Plugin

// Buefy
import Buefy from 'buefy'
Vue.use(Buefy)
// Buefy

// Moment JS
import moment from 'moment'
Vue.prototype.moment = moment
// Moment JS

// VUE Scroll TO
var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
})
// VUE Scroll TO

// AOS
import AOS from 'aos'
import 'aos/dist/aos.css'
export default ({ app }) => {
    app.AOS = new AOS.init({
        duration: 1000, // values from 0 to 3000, with step 50ms
        offset: 100, // offset (in px) from the original trigger point
        // disable: "mobile",
    })
}
// AOS

// VUE Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDKoQ3W2EhDYoB6oUBTvz4HqxeTdvPI7Vk',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
})
// VUE Google Maps

// Auth Plugin
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
//import driverOAuth2Google    from '@websanova/vue-auth/dist/drivers/oauth2/google.esm.js';
//import driverOAuth2Facebook  from '@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js';

let base_lang = i18n.messages[i18n.locale].basepath

Vue.use(auth, {
    plugins: {
        http: Vue.prototype.$axios, // Axios
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey: 'type',
        notFoundRedirect: { name: 'user-account' },
        loginData: {
            url: CONFIG.BASE_URL + '/wp-json/jwt-auth/v1',
        },
        fetchData: {
            url: CONFIG.BASE_URL + '/wp-json/p8app/v1/user/me/',
        },
        refreshData: {
            enabled: false,
        },
        logoutData: {
            makeRequest: false,
            redirect: base_lang,
        },
    },
})
// Auth

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
